// src/utils/storeData.js

// Store ID to Name mapping
export const storeNames = {
    "1": "Steam",
    "2": "GamersGate",
    "3": "GreenManGaming",
    "4": "Amazon",
    "5": "GameStop",
    "6": "Direct2Drive",
    "7": "GOG",
    "8": "Origin",
    "9": "Get Games",
    "10": "Shiny Loot",
    "11": "Humble Store",
    "12": "Desura",
    "13": "Uplay",
    "14": "IndieGameStand",
    "15": "Fanatical",
    "16": "Gamesrocket",
    "17": "Games Republic",
    "18": "SilaGames",
    "19": "Playfield",
    "20": "ImperialGames",
    "21": "WinGameStore",
    "22": "FunStockDigital",
    "23": "GameBillet",
    "24": "Voidu",
    "25": "Epic Games Store",
    "26": "Razer Game Store",
    "27": "Gamesplanet",
    "28": "Gamesload",
    "29": "2Game",
    "30": "IndieGala",
    "31": "Blizzard Shop",
    "32": "AllYouPlay",
    "33": "DLGamer",
    "34": "Noctre",
    "35": "DreamGame",
  };
  
  // Store ID to Icon mapping
  export const storeIcons = {
    "1": "https://www.cheapshark.com/img/stores/icons/0.png",
    "2": "https://www.cheapshark.com/img/stores/icons/1.png",
    "3": "https://www.cheapshark.com/img/stores/icons/2.png",
    "4": "https://www.cheapshark.com/img/stores/icons/3.png",
    "5": "https://www.cheapshark.com/img/stores/icons/4.png",
    "6": "https://www.cheapshark.com/img/stores/icons/5.png",
    "7": "https://www.cheapshark.com/img/stores/icons/6.png",
    "8": "https://www.cheapshark.com/img/stores/icons/7.png",
    "9": "https://www.cheapshark.com/img/stores/icons/8.png",
    "10": "https://www.cheapshark.com/img/stores/icons/9.png",
    "11": "https://www.cheapshark.com/img/stores/icons/10.png",
    "12": "https://www.cheapshark.com/img/stores/icons/11.png",
    "13": "https://www.cheapshark.com/img/stores/icons/12.png",
    "14": "https://www.cheapshark.com/img/stores/icons/13.png",
    "15": "https://www.cheapshark.com/img/stores/icons/14.png",
    "16": "https://www.cheapshark.com/img/stores/icons/15.png",
    "17": "https://www.cheapshark.com/img/stores/icons/16.png",
    "18": "https://www.cheapshark.com/img/stores/icons/17.png",
    "19": "https://www.cheapshark.com/img/stores/icons/18.png",
    "20": "https://www.cheapshark.com/img/stores/icons/19.png",
    "21": "https://www.cheapshark.com/img/stores/icons/20.png",
    "22": "https://www.cheapshark.com/img/stores/icons/21.png",
    "23": "https://www.cheapshark.com/img/stores/icons/22.png",
    "24": "https://www.cheapshark.com/img/stores/icons/23.png",
    "25": "https://www.cheapshark.com/img/stores/icons/24.png",
    "26": "https://www.cheapshark.com/img/stores/icons/25.png",
    "27": "https://www.cheapshark.com/img/stores/icons/26.png",
    "28": "https://www.cheapshark.com/img/stores/icons/27.png",
    "29": "https://www.cheapshark.com/img/stores/icons/28.png",
    "30": "https://www.cheapshark.com/img/stores/icons/29.png",
    "31": "https://www.cheapshark.com/img/stores/icons/30.png",
    "32": "https://www.cheapshark.com/img/stores/icons/31.png",
    "33": "https://www.cheapshark.com/img/stores/icons/32.png",
    "34": "https://www.cheapshark.com/img/stores/icons/33.png",
    "35": "https://www.cheapshark.com/img/stores/icons/34.png",
  };
  